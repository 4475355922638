<template>
  <div class="point_data">
    <div class="data_title_box">
      <h2>社内貢献PointData</h2>

      <button class="filter" @click="handleFilter">
        Filter<span class="material-symbols-outlined"> filter_alt </span>
      </button>
    </div>

    <div v-if="is_show_table">
      <table>
        <tr>
          <th>
            <div v-if="!is_show_addform">#</div>
            <div v-else>
              <div>全て選択</div>
              <div>
                <input name="all_check" type="checkbox" class="edit_check" v-model="selectAllEngineers"
                  @change="selectAllEngineersChanged" />
              </div>
            </div>
          </th>
          <th>エンジニア名</th>

          <th>
            <div v-if="!isFilter">エリア</div>
            <div v-else>
              エリア<select name="area" v-model="area_selected" @change="handleAreaChange" class="table_title_select">
                <option disabled value="">選択</option>
                <option value="sapporo">札幌</option>
                <option value="morioka">盛岡</option>
                <option value="oosaka">大阪</option>
                <option value="fukuoka">福岡</option>
                <option value="all">すべて</option>
              </select>
            </div>
          </th>

          <th>
            <div v-if="!isFilter">担当者</div>
            <div v-else>
              担当者<select name="pic" v-model="pic_selected" @change="handlePICChange" class="table_title_select">
                <option disabled value="">選択</option>
                <option value="kiyanagi">鬼柳</option>
                <option value="hata">畑</option>
                <option value="oda">小田</option>
                <option value="all">すべて</option>
              </select>
            </div>
          </th>

          <th>
            <div v-if="!isFilter">保有ポイント数</div>
            <div v-else>
              保有ポイント数<select name="enter_date" v-model="hold_point_selected" @change="handleSortPoint"
                class="table_title_select">
                <option disabled value="">選択</option>
                <option value="0">リセット</option>
                <option value="1">多い順</option>
                <option value="2">少ない順</option>
              </select>
            </div>
          </th>

          <th>
            <div>換金最大額</div>
          </th>

          <th>利用申請</th>

          <th>
            <div>最終取得日</div>
          </th>
        </tr>

        <tr v-for="(defaultdata, index) in currentPageItems" :key="defaultdata.id">
          <td>
            <div v-if="!is_show_addform">
              {{ (index + 1).toString().padStart(2, "0") }}
            </div>
            <div v-if="is_show_addform">
              <input name="engineer_check" type="checkbox" class="edit_check" v-model="defaultdata.isSelected" @change="
                checkUsers(
                  defaultdata.isSelected,
                  defaultdata.id,
                  defaultdata.last_name,
                  defaultdata.first_name
                )
                " />
            </div>
          </td>
          <td>
            <div class="default_engineer_name_frame" @click="engineerCheck(defaultdata.id)" v-if="!is_show_addform">
              <div v-if="defaultdata.face_url">
                <img :src="defaultdata.face_url" class="face" />
              </div>
              <div v-else>
                <img src="@/assets/mik_green.png" class="face" />
              </div>
              <div class="engineer_name">
                {{ defaultdata.last_name }} {{ defaultdata.first_name }}
              </div>
            </div>
            <div class="rest_engineer_name_frame" v-else>
              <div v-if="defaultdata.face_url">
                <img :src="defaultdata.face_url" class="face" />
              </div>
              <div v-else>
                <img src="@/assets/mik_green.png" class="face" />
              </div>
              <div class="engineer_name">
                {{ defaultdata.last_name }} {{ defaultdata.first_name }}
              </div>
            </div>
          </td>
          <td>
            <div v-if="defaultdata.area">
              <AreaStatus :id="defaultdata.id" :area="defaultdata.area" />
            </div>
          </td>
          <td>
            <div v-if="defaultdata.pic">
              <PICStatus :id="defaultdata.id" :pic="defaultdata.pic" />
            </div>
          </td>
          <td>
            <div class="table_item">{{ defaultdata.hold_point }}pt</div>
          </td>
          <td>
            <div class="table_item">{{ defaultdata.can_change_money }}円</div>
          </td>

          <td>
            <div v-if="defaultdata">
              <DefaultStatus :is_submit="defaultdata.is_submit" />
            </div>
          </td>

          <td>
            <div class="table_item" v-if="defaultdata && defaultdata.final_change">
              <div v-if="typeof defaultdata.final_change === 'string'">
                {{ defaultdata.final_change }}
              </div>
              <div v-else>{{ formDate(defaultdata.final_change) }}</div>
            </div>
          </td>
        </tr>
      </table>

      <div class="data_footer">
        <div class="previous_frame">
          <button class="previous_button" @click="prevPage" v-if="currentPage !== 1">
            <span class="material-symbols-outlined"> west </span>Previous
          </button>
        </div>
        <div class="pagenation">
          <ul>
            <li v-for="page in totalPages" :key="page" @click="changePage(page)"
              :class="{ 'active': page === currentPage }">

              <div class="page_number">{{ page }}</div>


            </li>
          </ul>
        </div>
        <div class="next_frame">
          <button class="next_button" @click="nextPage" v-if="currentPage !== totalPages">
            Next<span class="material-symbols-outlined"> east </span>
          </button>
        </div>
      </div>
    </div>
    <div v-else>
      <Pulse />
    </div>
  </div>
</template>

<script>
import DefaultStatus from "./DefaultStatus.vue";
import { ref, onMounted, watch, toRef, onBeforeMount } from "vue";

import PointAddForm from "./PointAddForm.vue";
import AreaStatus from "../home_comp/AreaStatus.vue";
import PICStatus from "../home_comp/PICStatus.vue";
import Pulse from "../Pulse.vue";
import getUsersPoint from "../composables/getUsersPoint";

export default {
  props: {
    isSelect: Boolean,
    defaultdatas: {
      type: Array,
      default: () => [],
    },
    isFix: Boolean,
    is_show_addform: Boolean,
    is_show_table: Boolean,
    now_page: Number,
  },
  name: "EngineerDefaultData",
  components: {
    DefaultStatus,
    PointAddForm,
    AreaStatus,
    PICStatus,
    Pulse,
  },
  data() {
    return {
      itemsPerPage: 6,
      currentPage: 1,
      item_num: 1,
      selectedItems: [],
      is_check_poinuser: false,
      isFilter: false,
      area_selected: null,
      pic_selected: null,
      hold_point_selected: null,
      selectAllEngineers: false,
    };
  },
  computed: {
    isPointSubumit() {
      if (this.defaultdatas) {
        if (this.defaultdatas.is_submit === true) {
          return "1";
        } else {
          console.log(this.defaultdatas.is_submit);
          return "2";
        }
      }
    },

    currentPageItems() {
      if (this.defaultdatas) {
        const startIndex = (this.currentPage - 1) * this.itemsPerPage;
        const endIndex = startIndex + this.itemsPerPage;
        return this.defaultdatas.slice(startIndex, endIndex);
      } else {
        return [];
      }
    },

    totalPages() {
      if (this.defaultdatas) {
        return Math.ceil(this.defaultdatas.length / this.itemsPerPage);
      }
      return 0;
    },
  },

  setup(props, context) {
    const is_check_poinuser = ref(false);
    const selectedUsers = ref([]);
    const currentPage = ref(1);
    const active_fontstyle = ref("")

    const changePage = (pageNumber) => {
      currentPage.value = pageNumber;
    };
    console.log("props.page", props.now_page);
    if (props.now_page === 0) {
      changePage(1);
      active_fontstyle.value = "bold"
    } else {
      changePage(props.now_page);
      active_fontstyle.value = "bold"
    }

    const handleAddFormClose = () => {
      is_show_addform.value = false;
    };

    const checkUsers = (isSelected, id, last_name, first_name) => {
      if (isSelected) {
        if (!selectedUsers.value.some((user) => user.id === id)) {
          selectedUsers.value.push({
            id: id,
            name: `${last_name} ${first_name}`,
          });
        }
      } else {
        selectedUsers.value = selectedUsers.value.filter(
          (user) => user.id !== id
        );
      }
      context.emit("checked-users", selectedUsers.value);
    };

    return {
      is_check_poinuser,
      selectedUsers,
      currentPage,
      active_fontstyle,
      changePage,
      handleAddFormClose,
      checkUsers,
    };
  },

  methods: {
    changePage(pageNumber) {
      this.currentPage = pageNumber;
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    engineerCheck(id) {
      if (this.selectedItems.length === 0) {
        this.selectedItems.push(id);
      } else {
        this.selectedItems.shift();
        this.selectedItems.push(id);
      }
      this.currentPageItems.forEach((defaultdata) => {
        if (!this.selectedItems.includes(defaultdata.id)) {
          defaultdata.isSelected = false;
        }
      });

      console.log(this.selectedItems);
      this.$emit("selected_engineer_id", this.selectedItems[0], this.currentPage);
    },

    selectAllEngineersChanged() {
      this.defaultdatas.forEach((data) => {
        data.isSelected = this.selectAllEngineers;
        this.checkUsers(
          data.isSelected,
          data.id,
          data.last_name,
          data.first_name
        );
      });
    },
    formDate(date) {
      const formed_date = date.toDate();
      return formed_date.toLocaleDateString("sv-SE");
    },
    handleFilter() {
      this.isFilter = !this.isFilter;
    },
    handleAreaChange() {
      this.$emit("area_selected", this.area_selected);
    },
    handlePICChange() {
      this.$emit("pic_selected", this.pic_selected);
    },
    handleSortPoint() {
      this.$emit("hold_point_selected", this.hold_point_selected);
    },
  },
};
</script>

<style>
.data_title_box {
  width: 1042px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.data_title_box button {
  width: 102px;
  height: 44px;
  border-radius: 12px;
  border: 1px solid #cdcde3;
  color: #a8a8bd;
  line-height: 19.6px;
  word-wrap: break-word;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.filter .material-symbols-outlined {
  color: #a8a8bd;
  font-size: 20px;
  margin-left: 5px;
}

.point_data {
  width: 1135px;
  height: 652px;
  border-radius: 16px;
  background: white;
  padding: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  margin-bottom: 20px;
  margin-right: 20px;
}

.point_data table {
  width: 1042px;
  height: 492px;
  border-collapse: collapse;
}

.point_data td {
  border-top: 1px solid #ddd;
}

.engineer_name_frame {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.point_data .engineer_name {
  width: 150px;
  display: flex;
  justify-content: flex-start;
  margin-left: 5px;
}

.face {
  width: 30px;
  height: 30px;
  background: #ddd;
  border-radius: 15px;
}

.pointdata_button {
  width: 110px;
  height: 30px;
  background: rgba(245, 195, 16, 1);
  color: white;
  border: none;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.data_footer {
  display: flex;
  margin-top: 22px;
  width: 1042px;
  height: 40px;

  justify-content: space-between;
  align-items: center;
}

.data_footer ul {
  display: flex;
  list-style: none;
  width: 292px;
  padding: 0;
  justify-content: space-around;
}

.data_footer li {
  width: 40px;
  height: 40px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  appearance: none;
}

.data_footer button {
  appearance: none;
  border: none;
  background: white;
  font-size: 16px;
}

.data_footer li :hover {
  width: 40px;
  height: 40px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #006aff;
  background: rgba(0, 106, 255, 0.5);
  border: none;
}

.previous_button {
  width: 114px;
  height: 36px;
  color: #2c3e50;
  border: 1px solid #cdcde3;
  background: white;
  border-radius: 8px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.previous_frame {
  width: 114px;
}

.next_button {
  width: 88px;
  height: 36px;
  color: #2c3e50;
  border: 1px solid #cdcde3;
  background: white;
  border-radius: 8px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.next_frame {
  width: 88px;
}

.material-symbols-outlined {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.8);
}

.data_footer .material-symbols-outlined {
  font-size: 16px;
  color: #2c3e50;
  width: 18px;
  display: flex;
  align-items: center;
}

.edit_check {
  width: 18px;
  height: 18px;
  background-color: #ffffff;
  border-radius: 50%;
  vertical-align: middle;
  border: 1px solid #2c3e50;
  appearance: none;
  -webkit-appearance: none;
  cursor: pointer;
}

.edit_check:checked {
  background: #58769f;
}

.point_button_container {
  display: flex;
  margin: 20px;
}

.point_top_button {
  width: 150px;
  height: 32px;
  background: rgba(245, 195, 16, 1);
  color: white;
  border: none;
  border-radius: 5px;
  margin-right: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.point_top_button:disabled {
  background-color: #ddd;
  cursor: not-allowed;
}

.top_button_long {
  width: 190px;
  height: 32px;
  background: rgba(245, 195, 16, 1);
  color: white;
  border: none;
  border-radius: 5px;
  margin-right: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.top_button_long_gray {
  width: 190px;
  height: 32px;
  background: gray;
  color: white;
  border: none;
  border-radius: 5px;
  margin-right: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.top_return_button {
  width: 150px;
  height: 32px;
  background: #2c3e5062;
  color: white;
  border: none;
  border-radius: 5px;
  margin-right: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.rest_engineer_name_frame {
  display: flex;
  align-items: center;
  border-radius: 10px;
  margin: 0 30px 0 30px;
  justify-content: center;
}

.pagenation li {
  margin: 0 10px;
}

.page_number {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.pagenation li.active .page_number {
  font-weight: bold;
}
</style>
